import React, { Component } from 'react';
import '../styles/SwiftFuture.css';

import { Header } from './Header';
import { Logo } from './Logo';
import { Title } from './Title';
import { Description } from './Description';

import logo from '../images/intro.png';

class SwiftFuture extends Component {
  state = {
    header: {
      text: 'Swift Future'
    },
    logo: {
      alt: 'Team Work',
      src: logo
    },
    title: {
      text: 'Coming Soon!'
    },
    description: {
      text: 'Delivering Superior Project Outcomes'
    }
  };
  render() {
    const { header, logo, title, description } = this.state;
    return (
      <div className="background">
        <Header text={header.text} />
        <Logo alt={logo.alt} src={logo.src} />
        <Title text={title.text} />
        <Description text={description.text} />
      </div>
    );
  }
}

export default SwiftFuture;
